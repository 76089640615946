.options {
    width: 67vw;
    height: 33vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 1px solid  var(--primary-color);
    padding: 0 1rem 2rem 1rem;
    overflow-y: auto;

    &--heading {
        text-align: center;
        margin: 1rem 0 0 0;
        color: var(--primary-color);
    }
    &__chart-label {
        margin: 1rem 0 0 0;
        width: 6rem;
        display: inline-block;
        color: var(--primary-color);
        font-weight: 700;
    }
    &--chart-title, &--chart-subtitle {
        display: inline-block;
        width: 30rem;
    }
    &--color-list {
        margin: 0 0 0 5rem;
    }
    &--chart-color-scheme {
        width: 10rem;
        text-align: center;
    }
    &__source-label {
        color: var(--primary-color);
        font-weight: 700;
        margin: 1rem 0 0 0;
    }
    &__chart-source {
        display: inline-block;
        width: 40rem;   
        margin: .25rem 0 0 0;
        padding: .25rem .5rem;
    }
    &__chart-decimal {
        padding: .25rem .5rem;
    }
}

#horizontal {
    margin: 0 .5rem 0 0;
}
#vertical {
    margin: 0 .5rem;
}