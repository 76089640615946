.template-card {
    padding: .25rem .5rem;
    width: 30%;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: #bfedef;
    }
    &--active {
        background-color: #bfedef;
    }
}