:root {
  --primary-color: #3880ff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Georgia, Arial, Helvetica, sans-serif;
}

body {
  overflow-x: hidden;
}