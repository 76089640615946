.file-upload {
    width: 33vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 25rem;
    border: 1px solid var(--primary-color);

    &--input {
        margin: 1rem 0 0 0;
        color: var(--primary-color);
        text-align: center;
    }
    &--fileName {
        margin: 1rem auto 0 auto;
        height: 1rem;
        text-align: center;
        font-weight: bold;
        color: var(--primary-color);
    }
    &--select {
        margin: 1rem auto 0 auto;
        width: 4rem;
        display: block;
        text-align: center;
    }
    &__chartMetaContainer {
        margin: 1rem auto .5rem auto;
        width: 80%;
        visibility: hidden;
    }
    &__metaDataLabel {
        text-align: center;
        color: var(--primary-color);
        margin-bottom: .5rem;
    }
    &__metaDataInput {
        width: 100%;
        border: 2px solid var(--primary-color);
        border-radius: 4px;
        padding: .25rem .5rem;
    }
    &--embed-button, &__reload-button {
        padding: .25rem .5rem;
        display: block;
        margin: 1rem auto 0 auto;
        color: white;
        background-color: var(--primary-color);
        width: 4rem;
        border-radius: 4px;
        cursor: pointer;
    }
    &--embed-code {
        margin: 1rem;
        font-weight: bold;
        width: 80%;
        margin: 1rem auto 0 auto;
    }
}

.dropzone-container {
    cursor: pointer;
    line-height: 1.3;
    padding: .25rem .5rem; 
    margin: 3rem auto 0 auto; 
    width: 80%;
    display: block;
    height: 4rem;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
}