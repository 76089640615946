.chart {
    width: 67vw;
    height: 67vh;
    position: fixed;
    bottom: 0;
    right: 0;
    border: 1px solid var(--primary-color);
    overflow-y: auto;

    &--heading {
        color: var(--primary-color);
        text-align: center;
        margin: 1rem 0 1rem 0;
    }
    &__source {
        width: 90%;
        margin: auto;
    }
}

#theChart {
    width: 90%;
    height: 300px;
    margin: .5rem auto;
}