.templates {
    width: 33vw;
    height: calc(100vh - 25rem);
    border: 1px solid var(--primary-color);
    position: fixed;
    top: 25rem;
    left: 0;

    &--heading {
        text-align: center;
        margin: 1rem 0;
        color: var(--primary-color);
    }
    &--selections {
        padding: 0 1rem;
    }
    &--template-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}